import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkaEkspertnyhVideo = () => {
  return (
    <>
      <Helmet>
        <title>Съемка экспертных видео в Москве цена от студии Elandic</title>
        <meta name="description" content="Съемка экспертных видео цена в Москве услуги под ключ от студии Elandic. Заказать услуги записи экспертных роликов для бизнеса стоимость от видеопродакшена." />
        <meta name="keywords" content="съемка экспертных видео" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемка экспертных видео '>
          <p>В современном мире видеоконтент – это не просто развлечение, а мощный инструмент продвижения и коммуникации. Экспертные видео - эффективный способ укрепить авторитет вашего бренда, привлечь новых клиентов и повысить узнаваемость.</p>

          <p>Студия Elandic предлагает профессиональную съемку экспертных видео в Москве, которая поможет вам передать знания и опыт ваших специалистов ярко, увлекательно и эффективно.</p>

          <p>Мы имеем опыт работы с различными клиентами и форматами видеоконтента. Наша команда профессиональных операторов, режиссеров и монтажеров поможет вам создать видео отличного качества, которое будет отражать ваши ценности и достижения.</p>

          <p>Мы предоставляем полный цикл услуг: от разработки концепции до монтажа и постпродакшна. Мы поможем вам определить цель видео, выбрать формат, подготовить скрипт, организовать съемку и сделать видео действительно эффективным.</p>

          <p>Мы учитываем все ваши пожелания и стремимся создать видео, которое будет отражать вашу уникальность. Мы готовы разработать нестандартные решения и предложить творческие подходы к съемке. Мы предлагаем гибкие условия сотрудничества и готовы рассмотреть индивидуальные предложения.</p>

          <p>Цена на съемку экспертных видео зависит от многих факторов: длительности ролика, места съемки, количества участников, необходимости специального оборудования и т.д. Чтобы получить индивидуальное коммерческое предложение, свяжитесь с нами через форму обратной связи на сайте или по телефону. Студия Elandic поможет вам создать экспертные видео высокого качества, которые принесут вам успех в бизнесе.</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkaEkspertnyhVideo);
